import React, {useState, useEffect, useCallback} from 'react'
import { 
  ButtonGroup, 
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography, 
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
  IconButton,
} from "@material-tailwind/react";

import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import FeeRateCard from './FeeRateCard';
import { isValidTaprootAddress, getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFee, getData, getMempoolUtxos} from '../util/inscribe-util';
import { encodedAddressPrefix, padding, tip, tippingAddress, royalty, mempoolNetwork, marketTipAddress } from '../configs/constant';
import { getIndexes, getInscribeTransactions, saveIndexes, saveInscribeTransactions } from '../util/api';
import { formatAddress } from '../util/format-data';
import { debounce } from 'lodash';

import { sendBtcTransaction } from 'sats-connect'

import { ToastContainer, toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';

import '../custom-toast.css';

export default function Inscribe() {

  const wallet = useSelector(state => state.wallet);


  const { Address, Script, Signer, Tap, Tx } = window.tapscript;

  const [inscriptionStatus, setInscriptionStatus] = useState(false);



  const inscribeOrdinals = async (inscribeTransaction, utxos) => {
    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    // Create a keypair to use for testing
    let seckey = new KeyPair(inscribeTransaction.privateKey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    console.log('PUBKEY', pubkey);

    let files = [];
    let text = inscribeTransaction.text;

    let mimetype = "text/plain;charset=utf-8";

    for(let i = 0; i< utxos.length; i++)
    {
      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
 
    for (let i = 0; i < files.length; i++) {

      console.log(files, '-----------')

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);


      inscriptions.push(
          {
              leaf: leaf,
              tapkey: tapkey,
              cblock: cblock,
              inscriptionAddress: inscriptionAddress,
              txsize: txsize,
              fee: inscribeTransaction.fee,
              script: script_backup,
              script_orig: script
          }
      );
    }
    
    for (let i = 0; i < inscriptions.length; i++) {
      await inscribe(inscriptions[i], utxos[i].vout, utxos[i].txid, utxos[i].value, seckey, inscribeTransaction);
      await waitSomeSeconds(3);
    }

  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey, inscribeTransaction) => {
    console.log(inscribeTransaction.ordinalAddress);

    let _toAddress;
    let _script;
    let toAddress = inscribeTransaction.ordinalAddress;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: amt2 - inscription.fee,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    
  }

  const isPushing = async () => {
    while (pushing) {
        await sleep(10);
    }
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleRecover = async () => {
    
    setInscriptionStatus(true);
    let inscriptions = await getInscribeTransactions();
    console.log(inscriptions);
    for(let inscribeTransaction of inscriptions) {
      if (isValidTaprootAddress(inscribeTransaction.ordinalAddress) == true) {
        let utxos = await getMempoolUtxos(inscribeTransaction.inscriptionAddress);
        console.log(utxos);
        await inscribeOrdinals(inscribeTransaction, utxos);
        //console.log("--valid taproot address--", inscribeTransaction.ordinalAddress);
      }
      else
      {
        console.log("--invalid taproot address--", inscribeTransaction.ordinalAddress);
      }
      
    }
    setInscriptionStatus(false);
    
  }

  const test = async () => {
    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    // Create a keypair to use for testing
    let seckey = new KeyPair('2c3b41a2e1af715489ee2601920affc7e933d7524ccaf3be94c7737575da12ed');
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});
    let fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);
    console.log("----------", fundingAddress);
  }

  return (
    <div className="flex flex-col gap-3 mt-5 md:w-[300px] w-full">
      {
        inscriptionStatus ? 
          <Spinner className="h-12 w-12" />:
          <Button onClick={() => {handleRecover()}}>Recover Inscription</Button>
      }
    </div>
  )
}
