export const getIndexes = async (p, limit, address = "", orderBy = "count", orderDir = "DESC" ) => {
  let url = '';
  if (p == 1)
    url = `https://api.brc444.com/theblockrunner/indexes?offset=&limit=${limit}&address=${address}&orderBy=${orderBy}&orderDir=${orderDir}`;
  else
    url = `https://api.brc444.com/theblockrunner/indexes?offset=${p}&limit=${limit}&address=${address}&orderBy=${orderBy}&orderDir=${orderDir}`;

  // Use fetch to send the request

  let result = await fetch(url);
  let data = await result.json();

  return data;
}

export const saveIndexes = async (receiveAddress, inscriptionAddress) => {
  let address = "";
  let size = inscriptionAddress.length;

  address = address + receiveAddress.split('').reverse().join('');
  address = address + inscriptionAddress;
  
  const url = 'https://api.brc444.com/theblockrunner/indexes';
  const data = {
    address: address,
    size: size
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getInscribeTransactions = async () => {
  let  url = `https://api.brc444.com/inscribe/getTransactionHistory`;

  // Use fetch to send the request

  let result = await fetch(url);
  let data = await result.json();

  return data;
}

// export const isValidTaprootAddress = async (address) => {
//   let  url = `https://api.brc444.com/checkValidTaprootAddress?address=${address}`;

//   // Use fetch to send the request

//   let result = await fetch(url);
//   let data = await result.json();

//   return data;
// }

export const saveInscribeTransactions = async (privateKey, paymentAddress, ordinalAddress, text, inscriptionAddress, fee) => {
  
  const url = 'https://api.brc444.com/inscribe/transactionHistory';
  const data = {
    privateKey: privateKey,
    paymentAddress: paymentAddress,
    ordinalAddress: ordinalAddress,
    text: text,
    inscriptionAddress: inscriptionAddress,
    fee: fee
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

