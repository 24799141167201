import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider
} from "@material-tailwind/react";

import { v4 as uuidv4 } from 'uuid';
import Connect from './Connect';
import Inscribe from './Inscribe';

import 'react-toastify/dist/ReactToastify.css';
import '../custom-toast.css';

export default function Indexer() {
  return (
    <div className="flex flex-col gap-5 md:p-10 p-5 bg-[#F0F0F0] min-h-[100vh] items-center w-full">
      <Typography variant="h1" color = "blue-gray">Recover Inscriptions</Typography>
      <Inscribe />
    </div>
  )
}
